.alert-div {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.status-banner-alert {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
