@use 'styles/utilities/_variables' as common;

.ds-modal-cta-row {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 9px 0;
}

@media (min-width: common.$bootstrap-sm-min-width) {
  .ds-modal-cta-row {
    margin: 18px 0 0;
  }
}
