@use 'styles/DesignSystem/colors' as colors;

.ds-tooltip-close-btn {
  border: 0;
  height: 16px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 16px;

  path {
    fill: colors.$light-text-color;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
