@use 'styles/DesignSystem/colors' as colors;
@use 'styles/utilities/_variables' as common;

.ds-solo-link {
  padding: 9px 0;
  white-space: nowrap;

  @include common.md-and-up {
    padding: 3px 0;
  }

  &.bold {
    font-weight: bold;
  }

  &.default {
    font-size: 16px;
    font-weight: 24px;
  }

  &.small {
    font-size: 14px;
    font-weight: 22px;
  }

  &.smallest {
    font-size: 13px;
    font-weight: 20px;
  }
}
