.banner-button {
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 16px 13px;
  position: absolute;
  right: 0;
  top: 0;
}

.closable-banner-wrapper {
  padding-left: 42px;
  padding-right: 60px;
  position: relative;
  text-align: center;
}
