@use 'styles/DesignSystem/colors' as colors;

.footer-row-content {
  max-width: 1000px;
  padding-bottom: 20px;

  .footer-text {
    color: colors.$white;
    font-family: 'Lato';
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
  }
  
  .footer-link {
    color: colors.$elsa;
    text-decoration: underline;
  }
}
