@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

.dropdown {
  display: flex;
}

.menu.menu {
  border: 0;
  box-shadow: 2px 2px 10px rgba(15, 26, 43, 0.25);
  margin-top: 6px;

  li > a:hover {
    background: colors.$dory-light;
    border-color: colors.$dory-light;
  }
}

.menu-item.menu-item {
  border: 0;
  border-radius: 4px;
  color: colors.$edna;
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin: 5px 12px;
  padding: 9px 12px;
  width: calc(100% - 24px);

  &:hover {
    background: colors.$dory-light;
    color: colors.$dumbo-lighter;
  }
}

@media screen and (min-width: common.$bootstrap-sm-min-width) {
  .menu-item.menu-item {
    padding: 7px 14px;
  }
}

