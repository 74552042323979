@use 'styles/DesignSystem/colors' as colors;

$text-sizes: (
  'small': 14px,
  'smallest': 13px,
  'default': 16px,
);

// Global heading properties used
@mixin ds-heading($font-size, $line-height) {
  color: colors.$edna;
  font-family: Lato;
  font-size: $font-size;
  font-weight: bold;
  line-height: $line-height;
  text-transform: none;
}

@mixin ds-product($font-size, $line-height) {
  color: colors.$edna;
  font-family: Lato;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin ds-editorial($font-size, $line-height) {
  color: colors.$edna;
  font-family: Merriweather;
  font-size: $font-size;
  line-height: $line-height;
}

.ds-heading-a {
  @include ds-heading(42px, 50px);
}

.ds-heading-b {
  @include ds-heading(36px, 46px);
}

.ds-heading-c {
  @include ds-heading(32px, 38px);
}

.ds-heading-d {
  @include ds-heading(28px, 35px);
}

.ds-heading-e {
  @include ds-heading(24px, 34px);
}

.ds-heading-f {
  @include ds-heading(20px, 28px);
}

.ds-heading-g {
  @include ds-heading(18px, 26px);
}

// Product Content Fonts
.ds-product {
  /*! Empty class for backwards compatibility */
}

.ds-product-default {
  @include ds-product(16px, 24px);
}

.ds-product-default-inherit-color {
  @include ds-product(16px, 24px);
  color: inherit;
}

.ds-product-small {
  @include ds-product(14px, 22px);
}

.ds-product-smallest {
  @include ds-product(13px, 20px);
}

// Editorial Content Fonts
.ds-editorial {
  /*! Empty class for backwards compatibility */
}

.ds-editorial p,
.ds-editorial li,
.ds-editorial-default {
  @include ds-editorial(16px, 29px);
}

.ds-editorial-smallest {
  @include ds-editorial(13px, 20px);
}

.ds-font-bold {
  font-weight: bold;
}

.ds-font-italic {
  font-style: italic;
}


@each $size-name, $size in $text-sizes {
  .#{$size-name} {
    font-size: $size;
  }
}

