@use 'styles/DesignSystem/colors' as colors;

.ds-modal-tertiary-btn-container {
  display: inline-block;
  vertical-align: middle;

  .ds-modal-tertiary-btn {
    margin: 0;
    padding: 12px 11px 9px;
  }
}

.ds-modal-leading-tertiary-btn-copy {
  color: colors.$edna;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
}
