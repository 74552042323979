@use 'styles/DesignSystem/colors' as colors;

.hover-display {
  display: none;
}

.always-display {
  display: inline;
}

.autocomplete-dropdown-item {
  align-items: center;
  background-color: colors.$white;
  border: 0;
  border-radius: 4px;
  color: colors.$edna;
  display: flex;
  font-size: 16px;
  justify-content: flex-start;
  padding: 9px 12px;
  text-align: left;
  width: 100%;

  &.reverse-order {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &:focus,
  &:hover {
    background-color: colors.$dory-light;
    color: colors.$light-text-color;
    cursor: pointer;
    outline: none;

    .hover-display {
      display: inline;
    }
  }
}

.icon-left {
  margin-right: 12px;
}

.icon-right {
  margin-left: 12px;
}
