@use 'styles/DesignSystem/colors' as colors;
@use 'styles/utilities/_variables' as common;

.ds-alert {
  border-radius: 4px;
  box-sizing: border-box;
  color: colors.$edna;
  font-family: 'Lato';
  font-size: 14px;
  line-height: 22px;
  max-width: 100%;
  padding: 18px 46px;
  position: relative;
  text-align: left;
  width: fit-content;

  @include common.md-and-up {
    max-width: 588px;
  }

  @include common.lg-and-up {
    max-width: 626px;
  }

  @include common.xl-and-up {
    max-width: 746px;
  }

  .dropbox {
    font-family: var(--__bodyFontStack);
    font-weight: var(--type__body__standard--fontweight_strong);
  }


  .alert-text {
    display: inline-block;
    word-break: break-word;
  }

  .ds-icon-default {
    position: absolute;
    right: 18px;
    top: 21px;

    path {
      fill: colors.$edna;

      &.dropbox {
        fill: var(--dig-color__text__base);
      }
    }

    &:hover {
      path {
        fill: colors.$edna-darker;

        &.dropbox {
          fill: var(--dig-color__text__base);
        }
      }
    }
  }

  &.dropbox {
    font-family: var(--__bodyFontStack);
  }

  &.ds-alert-success {
    background-color: colors.$success-alert-background-color;
    border: 2px solid colors.$peter-pan-light;
  }

  &.ds-alert-warning {
    background-color: colors.$simba-lighter;
    border: 2px solid colors.$simba;
  }

  &.ds-alert-error {
    background-color: colors.$sebastian-lighter;
    border: 2px solid colors.$sebastian-light;

    &.dropbox {
      background-color: var(--dig-color__alert__surface);
      border: 0;
    }
  }

  &.ds-alert-info {
    background-color: colors.$elsa-light;
    border: 2px solid colors.$elsa-dark;
  }
}
