@use 'styles/DesignSystem/colors' as colors;

.search-bar-wrapper {
  background-color: colors.$white;
  position: relative;

  .search-bar-input {
    display: flex;

    input {
      line-height: 36px;
    }

    button {
      white-space: nowrap;
    }
  }
}
