@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

.banner {
  align-items: center;
  background-color: colors.$elsa-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6px 20px;
  text-align: center;

  @include common.lg-and-up {
    flex-direction: row;
    height: 47px;
  }
}

.auto-create-banner-text {
  margin-bottom: 0;
  margin-right: 0;

  @include common.lg-and-up {
    margin-right: 18px;
  }
}

.auto-create-banner-link {
  background: none;
  border: 0;
  color: colors.$dory;
  font-weight: 700;
  line-height: 22px;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    opacity: 0.5;
    text-decoration: none;
  }
}

