@use 'styles/DesignSystem/colors' as colors;
@use 'styles/utilities/_variables' as common;

.updated-forms-banner {
  align-items: center;
  background-color: colors.$elsa-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 6px;
  padding-top: 6px;

  @include common.lg-and-up {
    flex-direction: row;
    height: 47px;
  }
}

.updated-forms-banner-message-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 6px;

  @include common.sm-and-up {
    margin-bottom: 6px;
  }

  @include common.lg-and-up {
    margin-right: 18px;
  }

  p {
    font-weight: 700;
    margin: 0 0 0 6px;
  }
}

.updated-forms-banner-links-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  @include common.sm-and-up {
    justify-content: center;
  }
}

.updated-forms-banner-link {
  font-weight: 700;
  height: 23px;
  line-height: 22px;
  margin-bottom: 9px;
  padding: 0;

  @include common.sm-and-up {
    margin-bottom: 6px;
  }

  @include common.lg-and-up {
    margin-bottom: 0;
  }
}

.updated-forms-banner-link-with-static-width {
  span {
    display: block;
    width: 115px;
  }
}

.updated-forms-banner-link-divide-border {
  border-right: 1px solid colors.$elsa-darker;
  margin-right: 12px;
  padding-right: 12px;
}

.updated-forms-banner-message-icon {
  height: 24px;
  width: 24px;
}

.meeting-minutes-link {
  border-right: 1px solid colors.$elsa-darker;
  margin-right: 12px;
  padding-right: 12px;

  @include common.sm-and-up {
    border-right: 0 none;
    margin-right: 0;
    padding-right: 0;
  }

  @include common.md-and-up {
    border-right: 1px solid colors.$elsa-darker;
    margin-right: 12px;
    padding-right: 12px;
  }
}

.release-of-liability-link {
  @include common.sm-and-up {
    border-right: 1px solid colors.$elsa-darker;
    margin-right: 12px;
    padding-right: 12px;
  }
}
