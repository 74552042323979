@use 'styles/DesignSystem/colors' as colors;
@use 'styles/utilities/_variables' as common;

.ds-modal-close-btn-container {
  height: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
}

.ds-modal-close-btn {
  path {
    fill: colors.$edna-lighter;
  }

  &.cursor-pointer {
    cursor: pointer;
  }
}

.ds-modal-close-btn:hover,
.ds-modal-close-btn:active {
  path {
    fill: colors.$edna;
  }
}

@media (min-width: common.$bootstrap-sm-min-width) { 
  .ds-modal-close-btn-container {
    right: 24px;
    top: 24px;
  }
}

