@use 'styles/DesignSystem/_colors' as colors;

.reactivation-banner-row {
  margin: 0;
}

.reactivation-banner-col {
  padding: 0;
}

.reactivation-banner {
  background-color: colors.$simba-light;
  font-weight: bold;
  height: 47px;
  text-align: center;

  p {
    line-height: 47px;
    margin: auto;
  }

  button {
    background: none;
    border: 0;
    color: #589ab9;
    display: inline-block;
    font-weight: bold;
    line-height: 0;
    min-height: 20px;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;
  }
}

@media (max-width: 370px) {
  .reactivation-banner {
    height: auto;

    p {
      line-height: 20px;
      padding: 5px 20px;
    }
  }
}


