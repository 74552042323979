@use 'styles/DesignSystem/colors' as colors;

.ds-tooltip {
  box-shadow: none;
  color: colors.$light-text-color;

  &:global(.tooltip.show) {
    opacity: 1;
  }

  :global(.tooltip-inner) {
    background-color: colors.$edna;
    max-width: none;
  }

  &:global(.bs-tooltip-top) {
    :global(.arrow)::before {
      border-top-color: colors.$edna;
      top: -0.01rem;
    }
  }

  &:global(.bs-tooltip-right) {
    :global(.arrow)::before {
      border-right-color: colors.$edna;
      right: -0.01rem;
    }
  }

  &:global(.bs-tooltip-bottom) {
    :global(.arrow)::before {
      border-bottom-color: colors.$edna;
      bottom: -0.01rem;
    }
  }

  &:global(.bs-tooltip-left) {
    :global(.arrow)::before {
      border-left-color: colors.$edna;
      left: -0.01rem;
    }
  }

  .ds-tooltip-body {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    justify-content: center;

    * {
      font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
}

.ds-tooltip-xs {
  font-size: 13px;

  :global(.tooltip-inner) {
    max-width: 200px;
  }

  .ds-tooltip-body {
    padding: 6px;
    text-align: center;
  }
}

.ds-tooltip-sm {
  :global(.tooltip-inner) {
    width: 261px;
  }

  .ds-tooltip-body {
    padding: 6px;
  }
}

.ds-tooltip-md {
  :global(.tooltip-inner) {
    width: 285px;
  }

  .ds-tooltip-body {
    padding: 12px;
  }
}

.ds-tooltip-lg {
  :global(.tooltip-inner) {
    width: 432px;
  }

  .ds-tooltip-body {
    padding: 30px;
  }
}

.ds-tooltip-wrapper {
  position: relative;
}

.ds-tooltip-target {
  background: none;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;

  &:active,
  &:focus,
  &:hover {
    border: 0;
  }
}

.ds-tooltip-default-header {
  font-weight: bold;
}
