.freemium-notification-button {
  display: inline-block;
  height: 32px;
  position: relative;
  width: 32px;
}

.notification-dot {
  position: absolute;
  right: 4px;
  top: 4px;
}

.downloads-remaining-row {
  padding: var(--spacing__unit--0_5) 0 var(--spacing__unit--1);
}
