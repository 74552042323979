// Warning: Modifying the styling here may modify pages that you did not intend to change
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.footer-toggle {
  bottom: 5px;
  left: 4px;
  position: absolute;
  z-index: 9999;

  &.dropbox {
    bottom: var(--spacing__unit--1);
    left: var(--spacing__unit--1);
    position: fixed;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}
