@use 'styles/utilities/_variables' as common;
$freemium-menu-header-wrapper-background-color: #6b788d;
$freemium-menu-header-sub-text-color: #b3bcc9;

.freemium-menu-header-wrapper {
  background-color: $freemium-menu-header-wrapper-background-color;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-bottom: 18px;
  padding-top: 18px;
  position: relative;
}

.freemium-menu-header-text {
  color: common.$white;
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  padding-left: 10px;
  text-align: left;
  @include common.sm-and-up {
    padding-left: 15px;
  }
}

.freemium-menu-header-sub-text {
  color: $freemium-menu-header-sub-text-color;
  font-size: 11px;
  margin: 0;
  padding-left: 10px;
  text-align: left;
  text-transform: uppercase;
  @include common.sm-and-up {
    padding-left: 15px;
  }
}

.freemium-menu-cta {
  margin-top: 6px;
  @include common.sm-and-up {
    position: absolute;
    right: 18px;
    top: 17px;
  }
}

.freemium-menu-arrow {
  border-bottom: 5px solid $freemium-menu-header-wrapper-background-color;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  color: $freemium-menu-header-wrapper-background-color;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 38px;
  width: 0;
}
