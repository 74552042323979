@use 'styles/DesignSystem/colors' as colors;

.autocomplete-dropdown-list {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(15, 26, 43, 0.25);
  padding: 12px;
  position: absolute;
  width: 100%;
  z-index: 10000;

  p {
    margin: 9px 12px 18px;
  }
}

.no-search-result-message {
  color: colors.$edna-light;
  text-align: left;
}
