@use 'styles/utilities/variables' as common;

.redesigned-footer {
  align-items: flex-start;
  background: var(--dig-color__secondary__base);
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--3);
  padding: var(--spacing__unit--5) var(--spacing__unit--3);
  text-align: left;

  @include common.sm-and-up {
    padding: 56px var(--spacing__unit--5);
  }
}

.footer-container {
  max-width: 1240px;
}

.sections {
  align-items: flex-start;
  display: flex;
  margin-bottom: var(--spacing__unit--3);
}

.section-header {
  margin-bottom: var(--spacing__unit--1_5);
}

.about-section {
  margin-bottom: var(--spacing__unit--5);

  @include common.sm-and-up {
    margin-bottom: 0;
  }

  .about-section-links {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.popular-documents-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(14, minmax(0, 1fr));

  @include common.md-and-up {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  @include common.lg-and-up {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
}

.social-media {
  align-items: center;
  display: flex;
  gap: var(--spacing__unit--2);
  justify-content: flex-start;
  margin-bottom: var(--spacing__unit--3);
}

.legal {
  margin-bottom: var(--spacing__unit--3);

  a:hover {
    color: var(--dig-color__primary__base);
  }
}
