@use 'styles/utilities/variables' as common;

.auto-create-modal.auto-create-modal {
  display: inline-table;
  height: auto;
  padding: 0;
  width: 100%;

  @include common.sm-and-up {
    width: 550px;
  }

  > div {
    height: auto;
  }
}

.auto-create-modal-body {
  display: grid;
  grid-auto-rows: auto 1fr auto;
  height: 100%;
  padding: 0;
}

.auto-create-modal-actions {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  padding: 0 24px 30px;

  @include common.sm-and-up {
    padding: 0 48px 30px;
  }

}

.auto-create-modal-cancel-cta {
  margin-right: 9px;
}

.description-section {
  padding: 18px 24px 30px;
  text-align: left;

  @include common.sm-and-up {
    padding: 18px 48px 30px;
  }
}

.description-section-form-list {
  display: grid;
  grid-gap: 6px;
  height: auto;
  margin-top: 18px;
}

.description-section-form-detail {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: left;
}

.description-section-form-list-more {
  font-style: italic;
  height: 30px;
  line-height: 30px;
}

.description-section-form-name {
  margin-bottom: 6px;
}

.auto-create-modal-illustration-img {
  display: block;
  height: auto;
  width: 100%;

  @include common.sm-and-up {
    height: 223px;
  }
}
