@use 'styles/DesignSystem/colors' as colors;
@use 'styles/utilities/variables' as utilities;

$modal-bg-overlay: rgba(0, 0, 0, 0.75);

.ds-modal-default {
  align-items: flex-start;
  background: utilities.$white;
  border-radius: 4px;
  bottom: 40px;
  box-sizing: content-box;
  display: flex;
  height: 226px;
  justify-content: center;
  left: 0;
  margin: auto;
  outline: none;
  overflow: auto;
  padding: 48px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 40px;
  width: 454px;
  z-index: 40;

  &.ds-small-modal {
    height: 138px;
  }

  &.ds-medium-modal {
    height: 186px;
  }
}

.ds-modal-overlay {
  background-color: $modal-bg-overlay;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

@media (max-width: utilities.$bootstrap-sm-min-width) {
  .ds-modal-default {
    width: 174px;
  }

  .ds-modal-mobile {
    bottom: auto;
    height: auto;
    left: 0;
    padding: 34px 0 28px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 81.25%;

    h2 {
      line-height: 29px;
    }
  }

  .ds-small-modal {
    height: 156px;
  }
}
