@use 'styles/DesignSystem/colors' as colors;

.link {
  color: colors.$dory;
  font-family: 'Lato';

  &:hover {
    color: colors.$dory;
    cursor: pointer;
  }

  &:active {
    color: colors.$ursula-darker;
  }
}
