@use 'styles/DesignSystem/colors' as colors;
@use 'styles/utilities/_variables' as common;

.tax-form-banner {
  align-items: center;
  background-color: colors.$elsa-light;
  display: flex;
  flex-wrap: wrap;
  height: 75px;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;

  @include common.sm-and-up {
    height: 47px;
  }

  @include common.md-and-up {
    padding-left: 42px;
    padding-right: 60px;
  }
}

.tax-form-banner-message {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 12px;
  
  @include common.sm-and-up {
    margin-bottom: 2px;
    margin-top: 0;
    padding-right: 18px;
  }
}

.tax-form-banner-link {
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 0;
  padding: 0;
  transform: translateY(-7px);
  
  @include common.sm-and-up {
    margin-bottom: 2px;
    transform: translateY(0);
  }
  
  a { 
    font-size: 14px; 
  }
}

.tax-form-banner-tooltip-icon {
  color: colors.$edna;
  margin-bottom: 2px;
  margin-left: 6px;
}

.tax-form-banner-divider {
  background-color: colors.$elsa-darker;
  height: 23px;
  margin: 0 12px;
  transform: translateY(-12px);
  width: 1px;

  @include common.sm-and-up {
    margin-top: 2px;
    transform: translateY(0);
  }
}

.tax-form-banner-line-break {
  flex-basis: 100%;
  height: 0;
  margin-bottom: 12px;
}
