@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/variables' as vars;

$text-color: vars.$white;

.outer-container {
  background-color: colors.$edna;
  color: $text-color;
  padding: 27px 10px 0;
}

.content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      margin-bottom: 2px;
    }

    a {
      color: vars.$footer-light-gray;
      font-size: 13px;
    }
  }

  h2 {
    color: $text-color;
    display: block;
    font-size: 15px;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
  }
}

.column {
  flex: 0 0 300px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
