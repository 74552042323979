@use 'styles/DesignSystem/_colors' as colors;

.banner {
  align-items: center;
  background-color: colors.$simba-light;
  color: colors.$edna;
  display: flex;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  line-height: 22px;
  min-height: 46px;
  padding: 12px 30px;
  text-align: center;

  div {
    min-width: 0;
  }
}
