.iframe {
  background-color: transparent;
  border: 0;
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: 1000;
}

/* 80px x 80px is the approximate size of the minimized chat icon */
.minimized {
  height: 80px;
  width: 80px;
}

.maximized {
  height: 524px;
  width: 374px;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
