@use 'styles/DesignSystem/colors' as colors;

// Set default, hover, and pressed state background colors
@mixin btn-colors($default-color, $dark-color, $darker-color, $normal-text-color, $active-text-color) {
  background: linear-gradient($default-color, $dark-color);

  &:not([disabled]) {
    &:hover {
      background: $dark-color;
    }

    &:active,
    &.toggle:focus {
      background: $darker-color;
      color: $active-text-color;
    }
  }

  @at-root a#{&} {
    &:hover {
      color: $normal-text-color;
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }
}

// Global button properties used for primary, secondary, and tertiary buttons
@mixin ds-btn() {
  align-items: center;
  border: 0;
  border-radius: 4px;
  color: colors.$light-text-color;
  cursor: pointer;
  display: flex;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin-left: 3px;
  margin-right: 3px;
  outline: none;
  white-space: nowrap;

  &.lg {
    padding: 15px 24px;

    &.option-square-padding {
      padding: 15px;
    }
  }

  &.md {
    padding: 9px 18px;

    &.option-square-padding {
      padding: 9px;
    }
  }

  &.sm {
    font-size: 14px;
    line-height: 22px;
    padding: 4px 12px;

    &.option-square-padding {
      padding: 4px;
    }
  }

  &.xs {
    font-size: 13px;
    line-height: 20px;
    padding: 2px 9px;

    &.option-square-padding {
      padding: 2px;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .ds-icon-left {
    display: flex;
    padding-right: 6px;
  }

  .ds-icon-right {
    display: flex;
    padding-left: 6px;
    padding-right: 0;
  }

  &.option-icon-only {
    .ds-icon-left,
    .ds-icon-right {
      padding-left: 0;
      padding-right: 0;
    }
  }

}

.ds-btn-primary,
.ds-btn-destructive,
.ds-btn-secondary,
.ds-btn-tertiary {
  @include ds-btn;
}

.ds-btn-primary {
  @include btn-colors(colors.$peter-pan, colors.$peter-pan-dark, colors.$peter-pan-darker, colors.$light-text-color, colors.$peter-pan-light);
  font-weight: bold;

  &.alternative {
    @include btn-colors(colors.$dory-lighter, colors.$dory-light, colors.$dory, colors.$light-text-color, colors.$elsa-light);
  }

  &.destructive {
    @include btn-colors(colors.$sebastian, colors.$sebastian-dark, colors.$sebastian-darker, colors.$light-text-color, colors.$sebastian-lighter);
  }

  &.dark {
    @include btn-colors(colors.$edna, colors.$edna-dark, colors.$edna-darker, colors.$light-text-color, colors.$dumbo-darker);
  }

  &.white {
    @include btn-colors(colors.$light-text-color, colors.$edna-dark, colors.$edna-darker, colors.$light-text-color, colors.$edna-dark);
  }
}

.ds-btn-secondary {
  @include btn-colors(colors.$secondary-btn-color, colors.$secondary-btn-color-dark, colors.$secondary-btn-color-darker, colors.$edna, colors.$edna);
  color: colors.$edna;

  &.dark {
    @include btn-colors(colors.$secondary-dark-btn-color, colors.$secondary-dark-btn-color-dark, colors.$secondary-dark-btn-color-darker, colors.$edna-dark, colors.$edna-dark);
  }
}

.ds-btn-tertiary {
  background-color: transparent;
  color: colors.$edna;
  font-size: 11px;
  font-weight: bold;
  padding: 0;
  text-transform: uppercase;

  &.sm,
  &.xs {
    font-size: 11px;
    line-height: 24px;
  }

  &:not([disabled]) {
    &:hover,
    &:active {
      color: colors.$edna-dark;
      font-weight: 900;
    }

    &.white {
      color: colors.$light-text-color;

      &:hover,
      &:active {
        opacity: 0.7;
      }
    }
  }

  &.alternative {
    color: colors.$dory-light;

    &:not([disabled]) {
      &:hover,
      &:active {
        color: colors.$dory;
      }
    }
  }
}

.ds-btn-icon {
  path {
    color: colors.$edna-lighter;
  }

  svg {
    vertical-align: middle;
  }

  &:not([disabled]) {
    &:hover,
    &:active {
      path {
        color: colors.$edna;
      }
    }
  }

  &:disabled {
    path {
      color: colors.$dumbo;
    }
  }
}

.ds-split-button {
  border-radius: 4px 0 0 4px;
  margin-right: 0;

  &.toggle {
    border-radius: 0 4px 4px 0;
    flex-grow: 1;
    margin-left: 0;
    padding-left: 0;

    &.ds-btn-primary {
      border-left: 1px solid #277327;
    }

    &.xs {
      padding: 4px;
    }

    &.sm {
      padding: 6px;
    }

    &.md {
      padding: 7px;
    }

    &.lg {
      padding: 8px;
    }
  }
}


