@use 'styles/DesignSystem/colors' as colors;

@mixin focused-input {
  border: 1px solid colors.$dory-light;
  box-shadow: 0 0 5px 0 colors.$dory-light;
  outline: 0;
}

.ds-text-input {
  font-family: 'Lato';

  input,
  textarea {
    border: 0;
    color: colors.$edna;
    width: 100%;

    &:focus {
      border: 0;
      outline: none;
    }
  }

  .input-children {
    align-items: center;
    color: colors.$edna-lighter;
    display: flex;
    justify-content: center;
    margin-right: 18px;
  }

  .text-input-close-button {
    align-items: center;
    background: none;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 11px 12px;

    path {
      fill: colors.$edna;
    }

    &:hover {
      cursor: pointer;
    }

    &.hide {
      visibility: hidden;
    }
  }


  .input-wrapper {
    border: 1px solid colors.$edna-lighter;
    border-radius: 4px;
    color: colors.$edna;
    display: inline-block;
    font-family: 'Lato';
    font-size: 18px;
    line-height: 26px;
    padding: 8px 18px;

    &.reduced-padding {
      padding-right: 6px;
    }

    &.input-focused {
      @include focused-input;

      + :global(.error-message),
      + :global(.form-error) {
        display: none;
      }
    }

    ::placeholder {
      color: colors.$edna-lighter;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      color: colors.$edna-lighter;
      font-style: italic;
    }

    .input-inner-flex {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
  }

  textarea {
    height: 150px;
    resize: none;
  }

  label {
    color: colors.$edna;
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 6px;
  }

  :global(.error-message),
  :global(.form-error) {
    color: colors.$sebastian-dark;
    display: none;
    font-family: 'Lato';
    font-size: 16px;
    line-height: 19px;
    margin-top: 5px;
  }

  .input-error {
    border: 1px solid colors.$sebastian-dark;
    box-shadow: 0 0 5px 0 colors.$sebastian-dark;
  }

  .input-error-description {
    :global(.error-message),
    :global(.form-error) {
      display: block;
    }
  }
}


.text-input-with-actions {
  display: flex;

  button {
    margin-left: 8px;
  }
}
