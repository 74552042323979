@use 'styles/utilities/_variables' as common;
$freemium-menu-icon-credits-badge-background-color: #4fb8d9;
$freemium-menu-icon-credits-badge-zero-credits-background-color: #ffdd6f;

.freemium-menu-icon {
  margin-left: 12px;
  margin-right: 6px;
  padding-bottom: 5px;
  width: 20px;
  @include common.sm-and-up {
    margin-left: 0;
    padding-bottom: 0;
  }
}

.freemium-menu-icon-credits-badge-wrapper {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  width: 47px;
}

.freemium-menu-icon-credits-badge {
  background: $freemium-menu-icon-credits-badge-background-color;
  border-radius: 30px;
  color: common.$white;
  font-size: 11px;
  left: 0;
  line-height: 18px;
  padding: 0 5px;
  position: absolute;
  text-align: center;
  top: 0;

  @include common.sm-and-up {
    left: 0;
    top: -7px;
  }

  &.zero-credits {
    background: $freemium-menu-icon-credits-badge-zero-credits-background-color;
  }
}
