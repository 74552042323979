@use 'styles/DesignSystem/colors' as colors;

.ds-icon-default {
  path {
    fill: colors.$edna-lighter;
  }

  &.cursor-pointer {
    cursor: pointer;
  }
}

.ds-icon-inherit-color {
  path {
    fill: currentColor;
  }

  polygon {
    fill: currentColor;
  }
}

.ds-icon-destructive {
  path {
    fill: colors.$sebastian;
  }
}
